<template>
	<div class="site-wrapper" :class="{ 'site-sidebar--fold': sidebarFold }" v-loading.fullscreen.lock="loading"
		element-loading-text="拼命加载中">
		<template v-if="!loading">
			<nav class="site-navbar" :class="'site-navbar--' + navbarLayoutType">
				<div class="site-navbar__header">
					<h1 class="site-navbar__brand" @click="$router.push({ name: 'home' })">
						<a class="site-navbar__brand-lg" href="javascript:;">
							<img src="@/assets/images/logo02.png" style="height: 30px;">
						</a>
						<a class="site-navbar__brand-mini" href="javascript:;">
							<img src="@/assets/images/logoMini02.png" style="height: 30px;" />
						</a>
					</h1>
				</div>
				<div class="site-navbar__body clearfix">
					<el-menu class="site-navbar__menu"
							mode="horizontal"
							default-active="0"
							style="margin-left: 10px;">
							<el-menu-item index="0">
								查看文件目录
							</el-menu-item>
					</el-menu>
					<el-menu class="site-navbar__menu site-navbar__menu--right" mode="horizontal">
						<el-menu-item class="back-list">
							<img src="@/assets/images/backIcon.png" alt="">
							返回
						</el-menu-item>
					</el-menu>
				</div>
			</nav>
			<div class="site-content__wrapper"
				:style="{ 'min-height': documentClientHeight + 'px','margin-left':'0px','padding':'80px 30px 30px 30px' }">
				<div class="mod-config">
					<el-card body-style="padding-bottom: 0;">
						<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
							<el-row>
								<el-col :span="20">
									<el-form-item label="标的物">
										<el-select class="selItemInput" v-model="subjectNo" placeholder="请选择">
											<el-option v-for="item in subjectList" :key="item.displayValue"
												:label="item.displayName" :value="item.displayValue">
											</el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="类型" v-if="tabIndex != 7">
										<el-select class="selItemInput" v-model="fileType" placeholder="请选择">
											<el-option v-for="item in majorList" :key="item.displayValue"
												:label="item.displayName" :value="item.displayValue">
											</el-option>
										</el-select>
									</el-form-item>
								</el-col>
								<el-col :span="4" class="selBtnGroup">
									<el-button class="primaryPlainBtn" type="primary" plain @click="resetFormData()">重置</el-button>
									<el-button class="primaryPlainBtn" type="primary" @click="selGetDataList()">检索</el-button>
								</el-col>
							</el-row>
						</el-form>
					</el-card>
					<el-row style="margin-top: 20px;">
						<el-col :span="12" :offset="12" style="text-align: right;">
								<el-pagination
									background
									@current-change="currentChangeHandle"
									layout="prev, pager, next"
									:current-page="pageIndex"
									:page-size="pageSize"
									:total="totalPage">
								</el-pagination>
						</el-col>
					</el-row>
					<div :style="siteContentViewHeight()">
					  <el-card
						style="margin-top: 20px; height: 100%;"
						body-style="height: 100%; padding: 0;">
						  <el-row style="height: 100%;">
							  <el-col :span="2" style="background-color: #e9f4fb; height: 100%;">
								<el-menu
								  :default-active="tabIndex"
								  class="tabMenu"
								  style="margin-top: 50px;">
									<el-menu-item index="0" @click="setTabIndex(0)" style="text-align: center;">
										监理收文
									</el-menu-item>
									<el-menu-item index="1" @click="setTabIndex(1)" style="text-align: center;">
										监理发文
									</el-menu-item>
									<el-menu-item index="2" @click="setTabIndex(2)" style="text-align: center;">
										送审文件
									</el-menu-item>
									<el-menu-item index="3" @click="setTabIndex(3)" style="text-align: center;">
										监理文件
									</el-menu-item>
									<el-menu-item index="4" @click="setTabIndex(4)" style="text-align: center;">
										周报
									</el-menu-item>
									<el-menu-item index="5" @click="setTabIndex(5)" style="text-align: center;">
										月报
									</el-menu-item>
									<el-menu-item index="6" @click="setTabIndex(6)" style="text-align: center;">
										进度控制文件
									</el-menu-item>
									<el-menu-item index="7" @click="setTabIndex(7)" style="text-align: center;">
										项目合同
									</el-menu-item>
								</el-menu>
							  </el-col>
							  <el-col :span="22" style="height: 100%;">
								  <el-table  v-if="tabIndex != 7"
								   ref="dataListTable"
									class="dataListTable"
									:data="dataList"
									stripe
									header-align="center"
									style="width: 100%;"
									header-cell-class-name="dataListTHeader">
									<el-table-column
										type="index"
										width="60"
										align="center">
									</el-table-column>
									<el-table-column
									  prop="categoryName"
									  label="文件名称"
									  align="center">
									</el-table-column>
									<el-table-column
									  prop="majorName"
									  label="类型"
									  align="center">
									</el-table-column>
									<el-table-column
									  prop="probContext"
									  label="上传时间"
									  align="center">
									</el-table-column>
									<el-table-column
									  label="操作"
									  align="center">
									  <template slot-scope="scope">
										  <el-button type="danger" size="small" @click="addOrUpdateHandle(scope.row.probId)">修改</el-button>
										  <el-button type="primary" size="small" @click="closeProblemHandle(scope.row.probId)">问题封闭</el-button>
										  <el-button type="success" size="small" @click="cancelProblemHandle(scope.row.probId)">问题取消</el-button>
									  </template>
									</el-table-column>
								  </el-table>
								  <el-table  v-if="tabIndex == 7"
								   ref="dataListTable"
									class="dataListTable"
									:data="dataList2"
									stripe
									header-align="center"
									style="width: 100%;"
									header-cell-class-name="dataListTHeader">
									<el-table-column
										type="index"
										width="60"
										align="center">
									</el-table-column>
									<el-table-column
									  prop="categoryName"
									  label="合同编号"
									  align="center">
									</el-table-column>
									<el-table-column
									  prop="majorName"
									  label="签订时间"
									  align="center">
									</el-table-column>
									<el-table-column
									  prop="probContext"
									  label="签订单位"
									  align="center">
									</el-table-column>
									<el-table-column
									  prop="probContext"
									  label="合同金额"
									  align="center">
									</el-table-column>
									<el-table-column
									  label="操作"
									  align="center">
									  <template slot-scope="scope">
										  <el-button type="danger" size="small" @click="addOrUpdateHandle(scope.row.probId)">下载</el-button>
									  </template>
									</el-table-column>
								  </el-table>
							  </el-col>
						  </el-row>
					  </el-card>
					</div>
				</div>
			</div>
		</template>
	</div>

</template>

<script>
	import $common from '@/utils/common.js'
	export default {
		provide() {
			return {
				// 刷新
				refresh() {
					this.$store.commit('common/updateContentIsNeedRefresh', true)
					this.$nextTick(() => {
						this.$store.commit('common/updateContentIsNeedRefresh', false)
					})
				}
			}
		},
		data() {
			return {
				workNo: '',
				subjectNo: '',
				tabIndex: "0",
				loading: true,
				major:"",
				//当前页码
				pageIndex: 1,
				//当前记录数
				pageSize: 10,
				//总页数
				totalPage: 0,
				dataList:[],
				dataList2:[]
			}
		},
		components: {},
		computed: {
			documentClientHeight: {
				get() {
					return this.$store.state.common.documentClientHeight
				},
				set(val) {
					this.$store.commit('common/updateDocumentClientHeight', val)
				}
			},
			sidebarFold: {
				get() {
					return this.$store.state.common.sidebarFold
				}
			},
			navbarLayoutType: {
				get() {
					return this.$store.state.common.navbarLayoutType
				}
			},
		},
		created() {
			this.loading = false
			this.getDataList();
		},
		mounted() {
			this.workNo = this.$route.query.workNo;
			this.resetDocumentClientHeight()
			this.getSubjectDropDown();
		},
		methods: {
			getSubjectDropDown(){
				this.$http({
					url:  this.$store.state.httpUrl + "/common/getSubjectDropDownByOut/" + this.workNo,
					method: "get",
					params: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.subjectList = data.body;
						if (this.subjectList){
							this.subjectNo = this.subjectList[0].displayValue;
						}
					}
				});
			},
			// 重置窗口可视高度
			resetDocumentClientHeight() {
				this.documentClientHeight = document.documentElement['clientHeight']
				window.onresize = () => {
					this.documentClientHeight = document.documentElement['clientHeight']
				}
			},
			siteContentViewHeight () {
			  var height = this.$store.state.common.documentClientHeight - 280;
			  return { height: height + 'px' }
			},
			setTabIndex(index){
				this.tabIndex = index;
				if(index != 7){
					this.getDataList()
				}else{
					this.getDataList2()
				}
			},
			getDataList(){

			},
			getDataList2(){

			},
			resetFormData(){
				this.major = "";
				this.fileType = "";
			},
			selGetDataList(){
				if(this.tabIndex != 7){
					this.getDataList()
				}else{
					this.getDataList2()
				}
			}
		}
	}
</script>

<style>
</style>
